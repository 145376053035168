import React, { FC, useCallback, useEffect, useState } from 'react'
import { HStack, VStack } from '@/XelaReact'
import Sidebar from '@/Components/Sidebar'
import { IconAlertTriangle, IconCheck } from '@tabler/icons-react'
import { router, usePage } from '@inertiajs/react'
import GlobalSearchModal from '@/Layouts/Modals/GlobalSearchModal'
import { showNotification } from '@mantine/notifications'
import AddQuickLeadModal from '@/Layouts/Modals/AddQuickLeadModal'
import DocumentEditorModal from '@/Layouts/Modals/DocumentEditorModal'
import useAuth from '@/Hooks/useAuth'
import mixpanel from 'mixpanel-browser'
import DocumentViewerModal from '@/Layouts/Modals/DocumentViewerModal'
import Notifications from '@/Components/Notifications/Notifications'
import axios from 'axios'
import { useLocalStorage } from '@mantine/hooks'
import CreateTaskModal from '@/Layouts/Modals/CreateTaskModal'
import EditTaskModal from '@/Layouts/Modals/EditTaskModal'
import CreateFollowUpModal from '@/Layouts/Modals/CreateFollowUpModal'
import EditFollowUpModal from '@/Layouts/Modals/EditFollowUpModal'
import UploadDocumentsModal from '@/Layouts/Modals/UploadDocumentsModal'
import DownloadDocumentsModal from '@/Layouts/Modals/DownloadDocumentsModal'
import useLocation from '@/Hooks/useLocation'
import Bugsnag from '@bugsnag/js'
import ChangeLoggedInUsers from '@/Components/ChangeLoggedInUsers'
import DashboardAnnouncementsDrawer from '@/Layouts/Modals/DashboardAnnouncementsDrawer'
import CallPopUpModal from '@/Components/CallPopUpModal'
import { LoadingOverlay } from '@mantine/core'
import EditUploadDocumentsModal from '@/Layouts/Modals/EditUploadDocumentsModal'
import AccountingCommentsDrawer from '@/Pages/Accounting/Drawer/AccountingCommentsDrawer'

const Authenticated: FC<{ children: React.ReactNode }> = ({ children }) => {
  const auth = useAuth()
  const location = useLocation()
  const flash = usePage().props.flash as Record<string, unknown>

  const [loadingIndicator, setLoadingIndicator] = useState<boolean>(false)

  const [currentVersion, setCurrentVersion] = useLocalStorage<string | null>({
    key: 'current_version',
    defaultValue: '1.0.0',
    getInitialValueInEffect: false,
  })

  const [fetchedVersion, setFetchedVersion] = useState<string | null>(null)
  const [showPaymentBanner, setShowPaymentBanner] = useState<boolean>(false)

  useEffect(() => {
    fetch(window.location.origin + '/meta.json?t=' + new Date().getTime())
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((data) => {
        setFetchedVersion(data.version)
        if (currentVersion === null) {
          setCurrentVersion(data.version)
        }
      })
      .catch(() => {
        console.log('Window Location Origin Failed')
      })
  }, [])

  const checkVersion = useCallback(() => {
    if (currentVersion != null && fetchedVersion != null && currentVersion !== fetchedVersion) {
      setCurrentVersion(fetchedVersion)

      setTimeout(() => {
        window.location.reload()
      }, 100)
    }
  }, [currentVersion, fetchedVersion])

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetch(window.location.origin + '/meta.json?t=' + new Date().getTime())
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
        .then((data) => {
          setFetchedVersion(data.version)
          if (currentVersion === null) {
            setCurrentVersion(data.version)
          }
        })
        .catch(() => {
          console.log('Window Location Origin Failed')
        })
    }, 3600000) // 30 Minutes

    return () => clearInterval(intervalId) // Clear interval on component unmount
  }, [currentVersion, fetchedVersion])

  useEffect(() => {
    const unbind = router.on('navigate', () => {
      checkVersion()
    })

    return () => {
      unbind()
    }
  }, [currentVersion, fetchedVersion])

  useEffect(() => {
    if (flash.message) {
      showNotification({
        autoClose: 5000,
        title: 'Success',
        message: flash.message as string,
        color: 'green',
        icon: <IconCheck />,
      })
    }

    if (flash.error) {
      showNotification({
        autoClose: 5000,
        title: 'Error',
        message: flash.error as string,
        color: 'red',
        icon: <IconAlertTriangle />,
      })
    }
  }, [flash.timestamp, flash.message, flash.error])

  useEffect(() => {
    const email = auth.user.demo_user_email || auth.user.email

    if (isProduction()) {
      Bugsnag.setUser(auth.user.id.toString(), email, `${auth.user.name}`.trim())
      Bugsnag.setContext(auth.agency.agency_name)
      Bugsnag.addMetadata('agency', {
        id: auth.agency.id,
        name: auth.agency.agency_name,
      })

      // Mixpanel Identify
      mixpanel.identify(email)
      // Set Mixpanel Group
      mixpanel.set_group('agency_id', auth.agency.id)
      // Set Mixpanel User
      mixpanel.people.set_once({
        $email: email,
        $first_name: auth.user.first_name,
        $last_name: auth.user.last_name,
        $phone: auth.user.phone,
        $avatar: auth.user.avatar,
        agency_id: auth.agency.id,
        agency_name: auth.agency.agency_name,
      })

      // June So
      if (window.Analytics) {
        window.Analytics.identify(email, {
          email,
          first_name: auth.user.first_name,
          last_name: auth.user.last_name,
          name: `${auth.user.first_name} ${auth.user.last_name}`.trim(),
          phone: auth.user.phone,
          avatar: auth.user.avatar,
          agency_id: auth.agency.id,
          agency_name: auth.agency.agency_name,
        })

        window.Analytics.group(auth.agency.id.toString(), {
          name: auth.agency.agency_name,
        })
      }
    }
  }, [])

  useEffect(() => {
    if (location.timezone) {
      axios.get(
        route('global.set-timezone', {
          _query: { timezone: location.timezone },
        })
      )
    }
  }, [location])

  useEffect(() => {
    return router.on('start', (event) => {
      if (event.detail.visit.method === 'get') {
        setLoadingIndicator(true)
      }
    })
  }, [])

  useEffect(() => {
    return router.on('finish', () => {
      setLoadingIndicator(false)
    })
  }, [])

  const fetchPaymentBanner = () => {
    axios
      .get<{
        show_payment_due_banner: boolean
      }>(route('global.agency-payment-banner'))
      .then((response) => {
        setShowPaymentBanner(response.data.show_payment_due_banner)

        setTimeout(() => {
          setShowPaymentBanner(false)
        }, 30000)
      })
  }

  useEffect(() => {
    fetchPaymentBanner()

    const intervalHandle = setInterval(() => {
      fetchPaymentBanner()
    }, 1800000)

    return () => {
      clearInterval(intervalHandle)
    }
  }, [])

  return (
    <>
      {loadingIndicator && (
        <div
          style={{
            position: 'absolute',
            width: '100vw',
            height: '100vh',
            zIndex: 1000,
            display: 'flex',
          }}
        >
          <LoadingOverlay visible={loadingIndicator} color={'blueX'} />
        </div>
      )}
      <div className="w-full overflow-x-hidden" style={{ position: 'relative', height: '100vh' }}>
        <div
          style={{
            position: 'absolute',
            right: 10,
            top: showPaymentBanner ? 51 : 14,
            zIndex: 100,
          }}
        >
          <Notifications />
        </div>
        <ChangeLoggedInUsers></ChangeLoggedInUsers>
        <HStack width="100%" height="100%" alignItems="flex-start">
          <Sidebar />
          <VStack width="100%" height="100%" alignItems="flex-start">
            {showPaymentBanner && (
              <div className="bg-red-600 text-sm font-semibold text-white px-4 py-2 flex items-center justify-center w-full">
                Dear User, Your subscription is about to expire. Please contact your SwiftAMS Relationship Manager to
                avoid closure of the account.
              </div>
            )}
            {children}
          </VStack>
        </HStack>
      </div>
      <GlobalSearchModal />
      <DocumentEditorModal />
      <DocumentViewerModal />
      <AddQuickLeadModal />
      <UploadDocumentsModal />
      <DownloadDocumentsModal />
      <CreateTaskModal />
      <EditTaskModal />
      <CreateFollowUpModal />
      <EditFollowUpModal />
      <CallPopUpModal />
      <DashboardAnnouncementsDrawer />
      <EditUploadDocumentsModal />
      <AccountingCommentsDrawer />
    </>
  )
}

export default Authenticated
